import { css } from "styled-components";
import { constants } from "./constants";

export const xSmallAndDown = (args:  TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.xSmallAndDown} {
    ${css(args)};
  }
`;

export const mediumAndUp = (args:  TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.medium} {
    ${css(args)};
  }
`;

export const mediumLargeAndDown = (args:  TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.mediumLargeAndDown} {
    ${css(args)};
  }
`;

export const mediumLargeAndUp = (args:  TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.mediumLargeAndUp} {
    ${css(args)};
  }
`;

export const small = (args:  TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.small} {
    ${css(args)};
  }
`;

export const largeAndUp = (args:  TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.largeAndUp} {
    ${css(args)};
  }
`;

export const mediumAndDown = (args:  TemplateStringsArray) => css`
  @media screen and ${constants.breakpoints.mediumAndDown} {
    ${css(args)};
  }
`;
