import styled from "styled-components";
import { Typography } from "antd";
import { small } from "../theme/mediaQueries";

const { Title } = Typography;

export const TitleH4 = styled(Title).attrs({
  level: 4,
})`
  &.ant-typography {
    margin-bottom: 20px;
    margin-right: 300px;
  }
  ${small`
  &.ant-typography {
    margin-right: 0px;
    font-size: 13px;
    margin-left: -10px;
  }
  `}
`;
