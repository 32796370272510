export const space = {
  zero: "0px",
  micro: "2px",
  mini: "4px",
  little: "6px",
  slim: "8px",
  cozy: "12px",
  moderate: "16px",
  normal: "24px",
  comfy: "32px",
};

export const additionalSpace = {
  fifty: "50px",
  seventyFive: "75px",
  oneHundred: "100px",
  oneHundredThirtyFive: "135px",
  twoHundred: "200px",
  twoHundredFifty: "250px",
  threeHundred: "300px",
};
