import { PagesUrls } from "namespace/routes.mapping";
import React, { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { NotFound } from "ui";
import {
  Dashboard,
  About,
  Products,
  Infrastructure,
  OrganizationStructure,
  Sustainability,
  Clients,
  ContactUs,
  Thankyou
} from "routes";


export const AppRoutes: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path={PagesUrls.Dashboard} element={<Dashboard />} />
      <Route path={PagesUrls.About} element={<About />} />
      <Route path={PagesUrls.Products} element={<Products />} />
      <Route path={PagesUrls.Clients} element={<Clients />} />
      <Route path={PagesUrls.Infrastructure} element={<Infrastructure />} />
      <Route path={PagesUrls.Organization} element={<OrganizationStructure />} />
      <Route path={PagesUrls.Sustainability} element={<Sustainability />} />
      <Route path={PagesUrls.ContactUs} element={<ContactUs />} />
      <Route path={PagesUrls.Thankyou} element={<Thankyou />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
