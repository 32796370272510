import { FloatButton, Layout } from "antd";
import styled from "styled-components";
import { colors, small } from "ui";

export const StyledLayoutComponent = styled(Layout)`
  min-height: 100vh;
  margin: 0 auto;
  .ant-layout-header {
    background: ${colors.white};
    min-height: 100px;
    ${small`
      padding: 0 25px;
      min-height: 85px;
    `}
  }
  .ant-layout-header:before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .ant-layout-footer {
    background:${colors.darkBlue};
    padding: 40px 50px;
    ${small`
    div {
      display: block;
      text-align: center;
      padding-bottom: 5px;  
    } 
    padding: 0px 15px;
    `}
`;

export const StyledContentWrapper = styled.div`
  ${small`
    padding: 10px 25px;
  `}
`;

export const StyledFloatButton = styled(FloatButton)`
  background-color: ${colors.whatsAppGreen};
  .ant-float-btn-body {
    background-color: ${colors.whatsAppGreen};
  }
  .ant-float-btn-body:hover {
    background-color: ${colors.whatsAppGreen};
  }
`;
