import styled from "styled-components";
import { Typography } from "antd";

const { Title } = Typography;

export const TitleH1 = styled(Title).attrs({
  level: 1,
})`
  &.ant-typography {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 33px;
  }
  &.ant-typography.ant-typography-secondary {
    color: #8b93a2;
    font-weight: normal;
  }
`;
