import { PagesUrls } from "namespace/routes.mapping";
import type { MenuProps } from "antd";

export const menuItems: MenuProps["items"] = [
  {
    label: "Home",
    key: PagesUrls.Dashboard
  },
  {
    label: "About",
    key: PagesUrls.About,
  },
  {
    label: "Products",
    key: PagesUrls.Products,
  },
  {
    label: "Infrastructure",
    key: PagesUrls.Infrastructure,
  },
  {
    label: "Sustainability",
    key: PagesUrls.Sustainability,
  },
  {
    label: "Organization",
    key: PagesUrls.Organization,
  },
  {
    label: "Clients",
    key: PagesUrls.Clients,
  },
  {
    label: "Contact Us",
    key: PagesUrls.ContactUs,
  },
];
