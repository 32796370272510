import React, { FC, useEffect } from "react";
import { AppLayout } from "layouts";
import { StyledDiv } from "routes/OrganizationStructure/OrganizationStructure.styled";
import Success from "ui/components/Success/Success";

export const Thankyou: FC = () => {
  useEffect(() => {
    document.title = `Uttam | Thankyou`;
  }, []);

  return (
    <AppLayout>
      <StyledDiv>
        <Success />
      </StyledDiv>
    </AppLayout>
  );
};
