import { Row, Card, Typography, Steps, Button, Divider } from "antd";
import styled from "styled-components";
import { colors, mediumAndDown, small, space, typography } from "ui";

const { Title, Paragraph } = Typography;

export const StyledRow = styled(Row)`
  margin: 10px;
`;

export const StyledH1 = styled(Title).attrs({
  level: 1,
})`
  padding-bottom: 15px;
`;

export const StyledSection = styled.div`
  text-align: center;
  margin-top: 30px;
  padding: 20px 0px;
  .ant-card-head-title {
    color: ${colors.themeOrangePrimary}!important;
    font-size: ${typography.size.bronto}!important;
  }
`;

export const StyledCard = styled(Card)`
  height: 100%;
  .ant-card-head-title {
    white-space: normal;
    font-size: ${typography.size.tera};
    padding: 10px 0px;
  }
`;

export const StyledDiv = styled.div`
  margin: 50px;
  ${small`
    margin: 25px 0px;
  }`}
`;

export const StyledDot = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-flex;
  background-color: ${colors.themeOrangePrimary};
  margin-right: 10px;
`;

export const StyledDashboard = styled.div`
  .ant-card {
    border-radius: 0.25rem;
    box-shadow: 0 5px 15px -5px #0000001a;
  }
  .ant-card-hoverable:hover {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%),
      0 10px 10px -5px rgb(0 0 0 / 2%);
  }
  .ant-card-cover img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin: auto;
    padding: ${space.comfy};
  }
  .ant-card-meta-detail {
    .ant-card-meta-title {
      font-size: ${typography.size.tera};
      padding: ${space.little} ${space.zero};
      text-align: center;
    }
    .ant-card-meta-description {
      color: ${colors.black};
    }
  }
`;

export const StyledSteps = styled(Steps)`
  margin-top: 60px;
  padding: 0px 50px;
  .ant-steps-item .ant-steps-item-content {
    margin-top: 50px;
    .ant-steps-item-title {
      color: ${colors.black}!important;
      font-size: ${typography.size.moderate}!important;
      margin-left: -10px;
    }
  }

  .ant-steps-icon {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    border: 2px solid #f7f7f7;
    background-color: ${colors.white} !important;
    vertical-align: middle;
    color: ${colors.themeOrangePrimary}!important;
    font-size: ${typography.size.bronto}!important;
    padding: 35px;
    border-radius: 10%;
  }
  .ant-steps-item-icon {
    margin-left: 0 !important;
  }
  .ant-steps-item-tail::after {
    background-color: ${colors.themeOrangeSecondary}!important;
    margin-left: 10px;
    height: 5px;
  }
`;

export const StyledStepsRow = styled.div`
  display: block;
  ${mediumAndDown`
    display: none;
  `}
`;

export const StyledMediumDeviceRow = styled.div`
  display: none;
  ${mediumAndDown`
    display: block;
    padding: 0px 50px;
  `}
  ${small`
    padding: 0px;
`}
`;

export const StyledSpacingDiv = styled.div`
  padding: 0px 50px;
  ${small`
    margin: 25px 0px;
    padding: 10px;
  }`}
`;

export const StyledLeftAlignDiv = styled.div`
  text-align: left;
  .ant-input-number {
    width: 100%;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.textGrey};
  padding-right: 20px;
  font-size: ${typography.size.kilo};
`;

export const StyledRoundButton = styled(Button)`
  background-color: ${colors.themeOrangePrimary}!important;
`;

export const StyledDarkBlueBgDiv = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
  background-color: ${colors.darkBlue};
  padding: 30px 50px;
  ${small`
      padding: 10px;
    `}
`;

export const StyledSmartDiv = styled.div`
  background-color: ${colors.white};
  // margin-bottom: 50px;
  padding: 5px 50px 30px 50px;
  ${small`
      padding: 10px;
    `}
`;

export const StyledProductsDiv = styled.div`
  padding: 10px 50px 25px 50px;
  ${small`
  margin: 25px 0px;
}`}
`;

export const StyledDivider = styled(Divider)`
  border-color: ${colors.themeOrangeSecondary} !important;
`;

export const StyledTitleCard = styled(Card)`
  .ant-card-body {
    display: none;
  }
  .ant-card-head {
    border-bottom: 0px;
  }
  .ant-card-head-title {
    font-size: ${typography.size.kilo} !important;
  }
`;

export const StyledVisionRow = styled(Row)`
  .anticon {
    margin-left: 5px !important;
  }
  .ant-list-item div {
    width: 100%;
  }
`;

export const StyledLargeDeviceParagraph = styled(Paragraph)`
  margin-top: ${space.moderate};
  display: block;
  ${mediumAndDown`
    display: none;
  `}
  color: ${colors.darkBlue};
  font-size: ${typography.size.kilo};
  padding: 0px 10px;
`;

export const StyledMediumDeviceParagraph = styled(Paragraph)`
  display: none;
  ${mediumAndDown`
    display: block;
  `}
  margin-top: ${space.moderate};
  color: ${colors.darkBlue};
  font-size: ${typography.size.kilo};
  padding: 0px 10px;
`;

export const StyledQualitySystemParagraph = styled(Paragraph)`
  margin-top: ${typography.size.kilo};
  color: ${colors.darkBlue};
  font-size: ${typography.size.kilo};
  // margin-bottom: 50px !important;
`;
