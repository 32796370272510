import React, { FC } from "react";
import { Layout } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { AppLayoutProps } from "./AppLayout.types";
import {
  StyledLayoutComponent,
  StyledContentWrapper,
  StyledFloatButton,
} from "./AppLayoutStyle";
import { Footer as FooterComponent, Header as HeaderComponent } from "ui";

const { Header, Content, Footer } = Layout;

export const AppLayout: FC<AppLayoutProps> = ({ children }) => (
  <StyledLayoutComponent>
    <Header>
      <HeaderComponent />
    </Header>
    <Content>
      <StyledContentWrapper>{children}</StyledContentWrapper>
    </Content>
    <Footer>
      <FooterComponent />
    </Footer>
    <a href="https://wa.me/919416499415" target={'_blank'} rel="noreferrer">
      <StyledFloatButton
        shape="circle"
        type="primary"
        icon={<WhatsAppOutlined />}
      />
    </a>
  </StyledLayoutComponent>
);
