import React, { FC, useEffect } from "react";
import { Card, Image, Row } from "antd";
import { AppLayout } from "layouts";
import { StyledH1, StyledParagraph } from "../About/About.styled";
import { StyledDiv, StyledCol, StyledImage } from "./Products.styled";
import { productImages } from "./constants";

export const Products: FC = () => {
  useEffect(() => {
    document.title = `Uttam | Products`;
  }, []);

  return (
    <AppLayout>
      <StyledDiv>
        <StyledH1> Products </StyledH1>
        <StyledParagraph>
          Our company specializes in the production of a diverse range of
          machined grey iron and ductile iron (SG, Spheroidal Graphite) castings
          meticulously crafted to meet both domestic and international
          standards, including IS, DIN, ASTM, ISO, JIS, and AS. Our primary
          focus is on serving the automotive and agricultural industries.
        </StyledParagraph>
        <StyledParagraph>
          Within our extensive product portfolio, you'll find a wide array of
          components, including but not limited to Brake Housings, Bull Pinion
          Carriers, Hydraulic Pistons, Greenhouse components, Hydraulic Lift
          Arms, Planet Carriers, Axle Hubs, Brake Drums, Pulleys, Covers, Gear
          Box Housings, and more. Our commitment to quality and precision is
          unwavering, ensuring that these components perform flawlessly in their
          intended applications.
        </StyledParagraph>
        <StyledParagraph>
          Furthermore, our manufacturing capabilities extend beyond standard
          offerings. We are well-equipped to accommodate specific component
          requirements, facilitating customized production that aligns perfectly
          with your unique needs and specifications. This flexibility
          underscores our dedication to meeting the evolving demands of modern
          industries.
        </StyledParagraph>
        <br />
        <br />
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          <Row gutter={[40, 40]}>
            {productImages.map((url, index) => (
              <StyledCol lg={8} md={12} xs={24} key={index}>
                <Card>
                  <StyledImage src={require(`../../assets/images/products/${url}`)} />
                </Card>
              </StyledCol>
            ))}
          </Row>
        </Image.PreviewGroup>
      </StyledDiv>
    </AppLayout>
  );
};
