import { Alert, Col } from "antd";
import { styled } from "styled-components";
import { colors, typography } from "ui";

export const StyledContactDiv = styled.div`
  margin: 50px 0px;
  background-color: ${colors.darkBlue};
  padding: 20px 0px;
  .ant-anchor-link-title {
    color: ${colors.themeOrangePrimary} !important;
    font-size: ${typography.size.giga};
    text-align: center;
    white-space: break-spaces !important;
    word-break: break-word;
  }
  .ant-anchor span {
    display: none;
  }
  .ant-anchor div {
    margin: auto;
  }
  .ant-anchor-wrapper-horizontal::before {
    border-bottom: 0px;
  }
`;

export const StyledParagraph = styled.p`
  text-align: center;
`;

export const StyledMapCol = styled(Col)``

export const StyledAlert = styled(Alert)`
  position: fixed;
  top: 0;
  right: 0;
`