import React, { FC, useEffect } from "react";
import { AppLayout } from "layouts";
import { StyledDiv, StyledH1, StyledParagraph } from "../About/About.styled";
import { Card, Row } from "antd";
import { StyledCol, StyledImage } from "routes/Products/Products.styled";
import SustainOne from "../../../src/assets/images/sustainability/sustain-1.jpg";
import SustainTwo from "../../../src/assets/images/sustainability/sustain-2.jpg";
import SustainThree from "../../../src/assets/images/sustainability/sustain-3.jpg";

export const Sustainability: FC = () => {
  useEffect(() => {
    document.title = `Uttam | Sustainability`;
  }, []);

  return (
    <AppLayout>
      <StyledDiv>
        <StyledH1> Sustainability </StyledH1>
        <StyledParagraph>
          At Uttam Foundries & Ispat Pvt Ltd., we are deeply committed to
          sustainability and environmental responsibility. Our premises are
          equipped with solar panels, enabling us to harness clean and renewable
          energy, producing up to 2000 units per month. We prioritize water
          conservation through rainwater harvesting and greywater reuse, saving
          1.5 lakh liters of water per month and reducing our reliance on
          traditional water sources. Our dedication to recycling is evident in
          our policy of recycling single-use plastics. We ensure zero use of
          virgin materials in our foundry operations by regenerating, remelting,
          and reusing foundry waste to promote resource utilization and waste
          reduction.
        </StyledParagraph>
        <StyledParagraph>
          Our goal of achieving carbon neutrality is well on track through tree
          plantations, with a target of planting 200 trees annually in and around our
          premises and neighboring villages.
        </StyledParagraph>
        <StyledParagraph>
          Furthermore, our company's sustainability policies, goals, and targets
          align with the UN Sustainable Development Goals (SDGs). These
          initiatives reflect our unwavering commitment to a greener future,
          where we aspire to make a positive impact on the environment while
          delivering high-quality products and services.
        </StyledParagraph>
        <br />
        <br />
        <Row gutter={[40, 40]}>
          {[SustainOne, SustainTwo, SustainThree].map((url, index) => (
            <StyledCol lg={8} md={12} xs={24} key={index}>
              <Card>
                <StyledImage src={url} />
              </Card>
            </StyledCol>
          ))}
        </Row>
      </StyledDiv>
      <br />
      <br />
    </AppLayout>
  );
};
