export const SAND_TESTING_EQUIPMENT = [
  "Sieve Shaker",
  "AFS Moisture Tester",
  "Sand Rammer",
  "Mould & Core Hardness Tester",
  "Permeability Tester",
  "Hydrometer",
  "GCS Testing Machine",
];

export const METALLOGRAPHIC_TESTING_EQUIPMENT = [
  "Carbon Silicon Analyzer",
  "Wet Analysis Lab",
  "Strohleins Apparatus",
  "Portable Metallurgical Microscope with Camera and Software",
  "Inverted Metallurgical Microscope",
  "22 element spectrometer",
];

export const PHYSICAL_TESTING_EQUIPMENT = [
  "Brinell Hardness Tester",
  "Rockwell Hardness Tester",
  "Immersion Pyrometer",
  "Universal Testing Machine (UTM)",
  "Weighing Balance (Platform Type)",
  "Weighing Balance (Pan Type)",
  "Ford Cup",
  "DFT Gauge",
];

export const DIMENSIONAL_TESTING_EQUIPMENT = [
  "Cordinate Measuring Machine (CMM)",
  "Roughness Tester",
  "Air Gauge Unit",
  "Pistol Calliper",
  "Micrometer (Range 0-400mm)",
  "Height Gauge (Range 0-600mm)",
  "Slip Gauge Set (Range 1-100mm)",
];

export const qualityPolicy: string[] = [
  "<b>Product & Process Consistency:</b>  Ensuring consistency in quality, cost, and delivery of our products and processes.",
  "<b>Waste Reduction & Housekeeping:</b>  Focusing on waste reduction and maintaining a clean and organized workspace.",
  "<b>Customer Satisfaction:</b>  Striving to exceed customer expectations and create delighted customers.",
  "<b>Compliance:</b>  Meeting and surpassing statutory and regulatory requirements set by customers.",
  "<b>Efficiency:</b>  Rationalizing our approach based on customer needs and product requirements.",
  "<b>Resource Optimization:</b>  Maximizing the utilization of resources for sustainable operations.",
  "<b>Plant Safety:</b>  Prioritizing safety measures within our plant.",
  "<b>Employee Welfare & Development:</b>  Caring for employee well-being and fostering professional growth.",
];

export const qualitySystems: string[] = [
  "ISO 9001:2015 certification",
  "Regular internal audits",
  "Scheduled Dimensional & Metallurgical Product and Process Audits",
  "Dedicated PDI Station with forced uni-directional material flow in batches",
  "Batch System: Inspection at first setup, in-process, final stages, and PDI. Comprehensive parameter recording and display of stage drawings",
  "Annual training plan for employees covering various aspects with a focus on training effectiveness",
  "Meticulous record-keeping for all processes, materials, and quality tests",
  "Strict adherence to quality standards for materials sourced from suppliers",
  "Diligent implementation of Kaizen practices for continual improvement",
];
