import { Card, Col, Image, List } from "antd";
import styled from "styled-components";
import { colors, small, typography } from "ui";
import factoryImage from '../../assets/images/infra/factory3.jpeg';
import shellCoreShooterImage from '../../assets/images/infra/shell_core_shooter.png';

export const StyledDiv = styled.div`
  margin: 50px;
  ${small`
    margin: 25px 0px;
  }`}
  .ant-divider-horizontal {
    margin-top: 40px;
  }
`;

export const StyledCol = styled(Col)`
  .ant-card {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    height: 100%;
  }
  .ant-image img {
    padding: 0;
    border-radius: 20px;
    border: 1px solid ${colors.themeOrangePrimary};
  }
  .ant-card-body .ant-list-items {
    text-align: left;
  }
  .ant-card-body .ant-list .ant-list-item {
    border: 0;
  }
`;

export const StyledImage = styled(Image)`
  padding: 20px 10px;
`;

export const StyledImageCard = styled(Card)`
  display: flex;
`;

export const StyledBgWrapper = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${factoryImage});
  background-size: cover;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  height: 100%;
`;

export const StyledFoundryBgWrapper = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${shellCoreShooterImage});
  background-size: cover;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  height: 100%;
`;

export const StyledList = styled(List)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  h3 {
    color: ${colors.white};
    padding: 10px;
    font-weight: bold;
  }
`;

export const StyledFoundryList = styled(List)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  h3 {
    color: ${colors.white};
    padding: 10px;
    font-weight: bold;
    font-size: ${typography.size.kilo};
  }
`;
