import { Card, Typography, Col } from "antd";
import styled from "styled-components";
import { colors, mediumAndDown, small, space, typography } from "ui";

const { Title, Paragraph } = Typography;

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${space.moderate};
  color: ${colors.darkBlue};
  font-size: ${typography.size.kilo};
`;

export const StyledDiv = styled.div`
  margin: 0px 50px;
  ${small`
    margin: 25px 0px;
  }`}
`;

export const StyledCol = styled(Col)`
  ${mediumAndDown`
      margin-bottom: 100px;
  `}
`;

export const StyledH1 = styled(Title).attrs({
  level: 1,
})`
  padding-bottom: 15px;
  text-align: center;
`;

export const StyledVisionDiv = styled.div`
  background-color: ${colors.darkBlue};
  padding: 10px;
`;
export const StyledIcon = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  width: 100px;
  height: 100px;
  border: 2px solid #f7f7f7;
  margin-top: -100px;
  margin-bottom: 20px;
  margin-left: auto;
  border-radius: 50%;
  margin-right: auto;
  background-color: #fff;
  ${mediumAndDown`
    width: 100px;
    height: 100px;
  `}
  .anticon {
    font-size: 50px;
    margin-left: 25px;
    margin-top: 25px;
    color: ${colors.themeOrangePrimary};
  }
`;

export const StyledCard = styled(Card)`
  height: 100%;
  margin: 0px 15px;
  h3 {
    text-align: center;
  }
`;

export const StyledRoundedDiv = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  width: 130px;
  height: 130px;
  border: 2px solid #f7f7f7;
  border-radius: 50%;
  background-color: #fff;
  margin: auto;
`;

export const StyledManagementCol = styled(Col)`
  h3 {
    margin-top: 20px;
    color: ${colors.themeOrangePrimary};
  }
  .ant-typography {
    text-align: center;
  }
`;

export const StyledResponsivePragraph = styled(Paragraph)`
  margin-top: ${space.moderate};
  color: ${colors.darkBlue};
  font-size: ${typography.size.kilo};
  min-height: 100px;
  ${mediumAndDown`
    min-height: 0px;
  `}
`;
