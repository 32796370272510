import React from "react";
import { Image } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  StyledLearnDiv,
  StyledCarousel,
  StyledDescription,
  StyledH3,
  StyledButton,
  StyledSecondDescription,
} from "./Carousel.styled";
import { Link } from "react-router-dom";
import { PagesUrls } from "namespace";
import SliderOne from "../../../../src/assets/images/slider/slider_One_mod.jpg";
import SliderTwo from "../../../../src/assets/images/slider/slider-2.png";

const images = [
  {
    image: SliderOne,
    description: (
      <>
        <StyledH3 italic>
          " Systematic Approach in Shaping MOLTEN METAL To Perfection - Our
          Endeavour "
        </StyledH3>
      </>
    ),
  },
  {
    image: SliderTwo,
    description: (
      <>
        <StyledH3 italic>
          " Where Foundry Meets Machine Shop Excellence "
        </StyledH3>
      </>
    ),
  },
];

const SliderComponent: React.FC = () => (
  <StyledCarousel autoplay effect="fade">
    {images.map(({ image, description }, index) => (
      <React.Fragment key={index}>
        <Image width="100%" preview={false} src={image} />
        {index === 1 ? (
          <StyledSecondDescription> {description} </StyledSecondDescription>
        ) : (
          <StyledDescription>{description}</StyledDescription>
        )}
        <StyledLearnDiv>
          <StyledButton type="primary" shape="round" size="large">
            <Link to={index === 0 ? PagesUrls.About : PagesUrls.Infrastructure}>
              Learn More <RightOutlined />
            </Link>
          </StyledButton>
        </StyledLearnDiv>
      </React.Fragment>
    ))}
  </StyledCarousel>
);

export const Slider = React.memo(SliderComponent);
