import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { colors, space } from "ui/elements";

export const GlobalStyles = createGlobalStyle` 
${normalize}
 .multi-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state
  .anticon-check {
  position: absolute;
  top: ${space.zero};
  left: ${space.zero};
  display: block;
  width: ${space.moderate};
  height: ${space.moderate};
  background-color: ${colors.white};
  border: 1px solid ${colors.checkboxBorderGrey};
  border-radius: ${space.micro};
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: ${space.cozy};
  margin-top: ${space.slim};
}

.multi-select-option .ant-select-item-option-content {
  margin-left: ${space.normal};
}

.multi-select-option:not(:first-child):not(.ant-select-item-option-selected)
  .ant-select-item-option-content::before {
  content: "";
  width: ${space.moderate};
  height: ${space.moderate};
  border: 1px solid ${colors.checkboxBorderGrey};
  border-radius: ${space.micro};
  background-color: ${colors.white};
  position: absolute;
  left: ${space.zero};
  margin-left: ${space.cozy};
  margin-top: ${space.micro};
}
 `;
