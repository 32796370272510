import { Pages } from "./routes.enum";

export type PageTypes = {
  [page in Pages]: string;
};

export const PagesUrls: PageTypes = {
  Dashboard: "/",
  About: '/about',
  Clients: '/clients',
  ContactUs: '/contact-us',
  Sustainability: '/sustainability',
  Infrastructure: '/infrastructure',
  Organization: '/organization',
  Products: '/products',
  Thankyou: '/thankyou'
};
