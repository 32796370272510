import React, { FC, useEffect } from "react";
import { Card, Image, Row } from "antd";
import { AppLayout } from "layouts";
import { StyledH1 } from "../About/About.styled";
import {
  StyledCol,
  StyledDiv,
  StyledImage,
} from "routes/Products/Products.styled";
import { clients } from "./constant";
import { StyledClientsQuote } from "./Clients.styled";

export const Clients: FC = () => {
  useEffect(() => {
    document.title = `Uttam | Clients`;
  }, []);

  return (
    <AppLayout>
      <StyledDiv>
        <StyledH1> Clients </StyledH1>
        <StyledClientsQuote italic>
          "Our clients inspire us to reach new heights, and we're privileged to
          be by their side on their journey to success."
        </StyledClientsQuote>
        <br />
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          <Row gutter={[40, 40]}>
            {clients.map(({ name, image }, index) => (
              <StyledCol lg={8} md={12} xs={24} key={index}>
                <Card title={name}>
                  <StyledImage preview={false} src={require(`../../assets/images/clients/${image}`)} />
                </Card>
              </StyledCol>
            ))}
          </Row>
        </Image.PreviewGroup>
      </StyledDiv>
    </AppLayout>
  );
};
