export const productImages: string[] = [
    "109-480x360.jpg",
    "107-480x360.jpg",
    "108-1024x681.jpg",
    "106-480x360.jpg",
    "101-1-480x360.jpg",
    "98-480x360.jpg",
    "99-480x360.jpg",
    "97-480x360.jpg",
    "96-480x360.jpg",
    "94-480x360.jpg",
    "1-1-480x360.jpg",
    "2-1-480x360.jpg",
    "85-480x360.jpg",
    "3-480x360.jpg",
    "4-480x360.jpg",
    "69-480x360.jpg",
    "65-480x360.jpg",
    "6-480x360.jpg",
    "5-480x360.jpg",
    "7-480x360.jpg",
    "45-480x360.jpg",
    "44-480x360.jpg",
    "43-480x360.jpg",
    "8-480x360.jpg",
    "14-480x360.jpg",
    "13-480x360.jpg",
    "12-480x360.jpg",
    "9-480x360.jpg",
    "11-480x360.jpg",
    "new-1.png",
    "new-2.png",
    "pr46.png",
]