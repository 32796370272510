import { Divider, Row, Space, Typography } from "antd";
import styled from "styled-components";
import {
  colors,
  largeAndUp,
  additionalSpace,
  small,
  space,
  typography,
} from "ui/elements";

const { Text } = Typography;

export const StyledUnorderedList = styled.ul`
  font-size: ${typography.size.uno};
  line-height: ${space.normal};
  list-style: none;
  padding: ${space.zero};
  display: inline-block;
  ${largeAndUp`
    display: flex;
    flex-wrap: wrap;
    li:last-child {
      order: -1;
    }
  `}
  li {
    margin: ${space.zero} ${additionalSpace.fifty} ${space.moderate}
      ${space.zero};
    display: inline-block;
    ${small`display: block;`}
  }
  li > a {
    text-decoration: none;
    color: ${colors.black};
  }
  li > a:hover {
    text-decoration: underline;
    color: ${colors.hoverBlue};
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${colors.black};
  ${small`
  .ant-space {
    flex: 70%;
  }
  .ant-typography {
    margin-top: 30px;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 40px;
  }
  .ant-col-xs-24 {
    justify-content: center;
    margin-top: 10px;
  }
  .anticon {
    text-align: center;
  }
  .ant-anchor-link {
    padding-block: 0 !important;
    margin-top: -4px;
  }
  `}
  .anticon {
    font-size: ${typography.size.bronto};
    color: ${colors.white};
    background-color: ${colors.themeOrangePrimary};
    padding: 20px;
    border-radius: 10%;
  }
`;

export const StyledSpace = styled(Space).attrs({
  size: 60,
})`
  &.ant-space-align-center {
    font-size: ${space.normal};
  }
  .anticon {
    color: ${colors.black};
  }
`;

export const StyledDivider = styled(Divider)`
  &.ant-divider-horizontal {
    border-color: ${colors.black};
  }
`;

export const StyledContactDiv = styled.div`
  margin-bottom: auto;
  color: ${colors.white};
  font-size: ${typography.size.giga};
  padding: 2px 15px;
  .ant-anchor-link-title {
    color: ${colors.themeOrangePrimary} !important;
    font-size: ${typography.size.giga};
    padding-top: 5px;
  }
  .ant-anchor-ink {
    display: none;
  }
`;

export const StyledText = styled(Text)`
  &.ant-typography {
    font-size: 24px;
  }
`;

export const StyledRow = styled(Row)`
  width: 100%;
  .ant-col-md-6 {
    text-align: center;
  }
`;

export const StyledCopyRight = styled.div`
  width: 100%;
  margin-top: 20px;
  p {
    font-size: ${typography.size.hecto};
  }
  ${small`
    justify-content: center;
    padding-bottom: 20px;
  `}
`