import React, { FC, useEffect } from "react";
import { AimOutlined, FireOutlined, RiseOutlined } from "@ant-design/icons";
import { Col, List, Row } from "antd";
import {
  SlidersOutlined,
  ControlOutlined,
  FastForwardOutlined,
  UserOutlined,
  FieldTimeOutlined,
  DeliveredProcedureOutlined,
  SettingOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { AppLayout } from "layouts";
import { Slider } from "ui/components";
import {
  StyledCard,
  StyledH1,
  StyledSection,
  StyledSteps,
  StyledStepsRow,
  StyledMediumDeviceRow,
  StyledSmartDiv,
  StyledVisionRow,
  StyledDarkBlueBgDiv,
  StyledDot,
  StyledQualitySystemParagraph,
} from "./Dashboard.styled";
import { StyledCol } from "routes/Infrastructure/Infrastructure.styled";
import { StyledIcon, StyledParagraph } from "routes/About/About.styled";
import { TitleH3 } from "ui";
import { qualityPolicy, qualitySystems } from "./Constants";

const itemsFirstRow = [
  {
    title: "Inherent Adaptability",
    icon: <SlidersOutlined />,
  },
  {
    title: "Casting And Machined Components, Under One Roof",
    icon: <ControlOutlined />,
  },
  {
    title: "Fast Component Development",
    icon: <FastForwardOutlined />,
  },
  {
    title: "Lean Approach",
    icon: <UserOutlined />,
  },
  {
    title: "Continuous Improvement",
    icon: <FieldTimeOutlined />,
  },
  {
    title: "Delivery : On Time, Every Time",
    icon: <DeliveredProcedureOutlined />,
  },
];

const itemsSecondRow = [
  {
    title: "Robust QMS Implementation",
    icon: <SettingOutlined />,
  },
  {
    title: "Eco-friendly Infrastructure",
    icon: <EnvironmentOutlined />,
  },
];

export const Dashboard: FC = () => {
  useEffect(() => {
    document.title = `Uttam: Best Iron Casting Foundry and Casting Foundry India`;
  }, []);

  return (
    <AppLayout>
      <Slider />
      <StyledSection>
        <StyledH1> Why Partner With Us ? </StyledH1>
        <StyledStepsRow>
          <StyledSteps labelPlacement="vertical" items={itemsFirstRow} />
        </StyledStepsRow>
        <StyledStepsRow>
          <Row>
            <Col lg={8}></Col>
            <Col lg={8}>
              <StyledSteps labelPlacement="vertical" items={itemsSecondRow} />
            </Col>
            <Col lg={8}></Col>
          </Row>
        </StyledStepsRow>
        <StyledMediumDeviceRow>
          <Row gutter={[16, 16]}>
            {[...itemsFirstRow, ...itemsSecondRow].map((item, index) => (
              <Col xs={24} sm={12} key={index}>
                <StyledCard title={item.icon} bordered={false}>
                  {item.title}
                </StyledCard>
              </Col>
            ))}
          </Row>
        </StyledMediumDeviceRow>
        <StyledDarkBlueBgDiv>
          <StyledVisionRow gutter={[40, 100]}>
            <StyledCol lg={8} xs={24}>
              <StyledCard bordered={false}>
                <StyledIcon>
                  <AimOutlined />
                </StyledIcon>
                <TitleH3> Vision </TitleH3>
                To master the art of shaping molten metal to perfection and
                consistently deliver high-quality machined castings worldwide.
                Our commitment is solely based on the unwavering quality of our
                finished products.
              </StyledCard>
            </StyledCol>
            <StyledCol lg={8} xs={24}>
              <StyledCard bordered={false}>
                <StyledIcon>
                  <FireOutlined />
                </StyledIcon>
                <TitleH3> Mission </TitleH3>
                We are dedicated to a relentless pursuit of innovation,
                technological advancement, and continuous improvement to not
                only meet but exceed our customers' expectations.
              </StyledCard>
            </StyledCol>
            <StyledCol lg={8} xs={24}>
              <StyledCard bordered={false}>
                <StyledIcon>
                  <RiseOutlined />
                </StyledIcon>
                <TitleH3> Values </TitleH3>
                At the core of our business are our fundamental values:
                unwavering business ethics, a steadfast commitment to safety,
                the well-being of our employees, and the efficient utilization
                of resources. These values form the bedrock of our operations
                and drive us to create a secure and organized working
                environment.
              </StyledCard>
            </StyledCol>
          </StyledVisionRow>
        </StyledDarkBlueBgDiv>
        <StyledSmartDiv>
          <StyledH1> Quality </StyledH1>
          <StyledVisionRow gutter={[40, 40]}>
            <StyledCol lg={12} xs={24}>
              <StyledCard title="Quality Policy" bordered={false}>
                <StyledParagraph>
                  At Uttam Foundries & Ispat Pvt. Ltd., our Quality Policy
                  revolves around the profitable production of grey and ductile
                  iron castings while fostering a safe, peaceful, and organized
                  working environment. Central to this policy is the adherence
                  of our quality management systems to globally recognized
                  standards and an unwavering commitment to continual
                  improvement of:
                </StyledParagraph>
                <List
                  itemLayout="horizontal"
                  dataSource={qualityPolicy}
                  renderItem={(item) => (
                    <List.Item>
                      <StyledDot />
                      <div dangerouslySetInnerHTML={{__html: item}} />
                    </List.Item>
                  )}
                />
              </StyledCard>
            </StyledCol>
            <StyledCol lg={12} xs={24}>
              <StyledCard title="Quality Systems" bordered={false}>
                <StyledQualitySystemParagraph>
                  At Uttam Foundries & Ispat Pvt Ltd, our quality systems
                  encompass a comprehensive array of processes and practices
                  designed to ensure consistent product quality and adherence to
                  industry standards.
                </StyledQualitySystemParagraph>
                <List
                  itemLayout="horizontal"
                  dataSource={qualitySystems}
                  renderItem={(item) => (
                    <List.Item>
                      <StyledDot />
                      <div> {item} </div>
                    </List.Item>
                  )}
                />
              </StyledCard>
            </StyledCol>
          </StyledVisionRow>
        </StyledSmartDiv>
      </StyledSection>
    </AppLayout>
  );
};
