import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";
import { PagesUrls } from "namespace";

export const NotFoundComponent: FC = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link to={PagesUrls.Dashboard}>
        <Button type="primary">Back Home</Button>
      </Link>
    }
  />
);

export const NotFound = React.memo(NotFoundComponent);
