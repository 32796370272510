export const clients = [
  {
    name: "MAHINDRA",
    image: "mahindra-logo.jpeg",
  },
  {
    name: "TMTL",
    image: "eicher-logo-new.png",
  },
  {
    name: "INTERNATIONAL TRACTORS LTD.",
    image: "sonalika_logo.png",
  },
  {
    name: "OSAW UDYOG",
    image: "garud_logo_new.png",
  },
];
