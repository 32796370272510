import React, { FC, useCallback, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { MenuProps, Row } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { PagesUrls } from "namespace";
import { menuItems } from "constants/AppMenu";
import {
  StyledLogoCol,
  StyledNavCol,
  StyledImage,
  StyledMenu,
  StyledDrawer,
  StyledButton,
  StyledSmallNavCol,
  StyledSpan,
} from "./header.styled";

export const HeaderComponent: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [current] = useState(location.pathname);

  const handleMenuClick: MenuProps["onClick"] = (menu) => {
    setIsDrawerVisible(false);
    navigate(menu.key);
  };

  const handleMenuDrawer = useCallback(() => {
    setIsDrawerVisible(!isDrawerVisible);
  }, [isDrawerVisible]);

  const onDrawerClose = useCallback(() => {
    setIsDrawerVisible(false);
  }, []);

  return (
    <Row align="middle" justify={"space-between"}>
      <StyledLogoCol md={8} xs={8}>
        <Link to={PagesUrls.Dashboard}>
          <StyledImage
            preview={false}
            src={require("../../../assets/images/logo/uttam-logo.png")}
          />
        </Link>
      </StyledLogoCol>
      <StyledNavCol md={16} xs={8}>
        <StyledMenu
          items={menuItems}
          onClick={handleMenuClick}
          selectedKeys={[current]}
          mode="horizontal"
        />
      </StyledNavCol>
      <StyledSmallNavCol>
        <StyledButton onClick={handleMenuDrawer}>
          {isDrawerVisible ? <CloseOutlined /> : <MenuOutlined />}
          <StyledSpan> Menu </StyledSpan>
        </StyledButton>
        <StyledDrawer
          placement="right"
          closable={false}
          onClose={onDrawerClose}
          open={isDrawerVisible}
        >
          <StyledMenu
            items={menuItems}
            onClick={handleMenuClick}
            selectedKeys={[current]}
            mode="inline"
          />
        </StyledDrawer>
      </StyledSmallNavCol>
    </Row>
  );
};

export const Header = React.memo(HeaderComponent);
