import { Button, Carousel } from "antd";
import { styled } from "styled-components";
import { colors, small, TitleH3, typography } from "ui/elements";

export const StyledCarousel = styled(Carousel)`
  .slick-list {
    position: relative;
  }
  .slick-slide {
    margin-bottom: 10px;
  }
  .slick-dots-bottom {
    bottom: 0px !important;
  }
  .slick-dots li {
    width: 15px !important;
  }
  .slick-dots li button {
    border: 2px solid ${colors.themeOrangeSecondary} !important;
    background-color: #fff !important;
    height: 15px;
    border-radius: 50%;
    opacity: 1;
  }
  .slick-dots .slick-active button {
    border: 2px solid ${colors.themeOrangeSecondary} !important;
    background-color: ${colors.themeOrangeSecondary} !important;
    height: 15px;
    border-radius: 50%;
  }
  .slick-slide img {
    position: relative;
  }
`;

export const StyledDescription = styled.div`
  position: absolute;
  bottom: 20%;
  padding: 20px 50px;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  text-align: center;
  ${small`
    bottom: 30%;
    background: rgba(255, 255, 255, 0.5);
  `}
  h1 {
    font-size: 50px !important;
    padding-bottom: 25px;
    ${small`
    font-size: 20px !important;
    padding-bottom: 0px;
      `}
  }
  h3 {
    padding-bottom: 25px;
    ${small`
    margin-top: 5px !important;
    font-size: 14px;
    padding-bottom: 0px;
  `}
  }
  button {
    ${small`
      margin: 5px 0px;
    `}
  }
`;

export const StyledSecondDescription = styled.div`
  position: absolute;
  bottom: 20%;
  padding: 20px 50px;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  text-align: center;
  ${small`
  bottom: 30%;
`}
  h1 {
    font-size: 50px !important;
    padding-bottom: 25px;
    ${small`
  font-size: 20px !important;
  padding-bottom: 0px;
    `}
  }
  h3 {
    padding-bottom: 25px;
    ${small`
  margin-top: 5px !important;
  font-size: 14px;
  padding-bottom: 0px;
`}
  }
  button {
    ${small`
    margin: 5px 0px;
  `}
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${colors.themeOrangePrimary}!important;
`;

export const StyledH3 = styled(TitleH3)`
  padding: 20px 10px;
  font-size: ${typography.size.bronto} !important;
  font-family: RubikLight;
  font-weight: bold !important;
  ${small`
    padding: 0px 10px;
    font-size: 16px !important;
    `}
`;

export const StyledLearnDiv = styled.div`
  position: absolute;
  bottom: 5%;
`;
