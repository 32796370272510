export const colors = {
  white: "#fff",
  black: "#000000",
  blackRgba: "rgba(0, 0, 0, 0.88)",
  hoverBlue: "#007bc0",
  lightGrey: "#eff1f2",
  warningRed: "#ff4d4f",
  textGrey: "#606f8a",
  checkboxBlue: "#1890ff",
  checkboxBorderGrey: "#d9d9d9",
  themeOrangePrimary: "#FC4C02",
  themeOrangeSecondary: "#FF4D00",
  darkBlue: "#253746",
  whatsAppGreen: "#25D366"
};
