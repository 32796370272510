import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";
import { PagesUrls } from "namespace";

const Success: React.FC = () => (
  <Result
    status="success"
    title="Thank you for contacting us!"
    subTitle="Our team will reach out to you soon!"
    extra={[
      <Link to={PagesUrls.Dashboard}>
        <Button type="primary">Back Home</Button>
      </Link>,
    ]}
  />
);

export default Success;
