import React, { FC, useEffect } from "react";
import { Anchor, Row } from "antd";
import {
  PhoneOutlined,
  EnvironmentOutlined,
  MailOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { AppLayout } from "layouts";
import { TitleH3 } from "ui/elements";
import {
  StyledCard,
  StyledCol,
  StyledDiv,
  StyledH1,
  StyledIcon,
} from "../About/About.styled";
import { StyledContactDiv, StyledParagraph } from "./Contact.styled";
import { StyledLeftAlignDiv } from "routes/Dashboard/Dashboard.styled";
import ContactForm from "./Components/Form";

export const ContactUs: FC = () => {
  useEffect(() => {
    document.title = `Uttam | Contact`;
  }, []);

  return (
    <AppLayout>
      <StyledH1> Contact Us </StyledH1>
      <StyledContactDiv>
        <StyledDiv>
          <Row gutter={[16, 16]}>
            <StyledCol lg={6} xs={24}>
              <StyledCard bordered={false}>
                <StyledIcon>
                  <PhoneOutlined />
                </StyledIcon>
                <TitleH3> Phone </TitleH3>
                <StyledParagraph>
                  <Anchor
                    affix={false}
                    direction="horizontal"
                    items={[
                      {
                        key: "phone",
                        href: "tel:+91-9416499415",
                        title: "+91-9416499415",
                        target: "__blank",
                      },
                    ]}
                  />
                </StyledParagraph>
              </StyledCard>
            </StyledCol>
            <StyledCol lg={6} xs={24}>
              <StyledCard>
                <StyledIcon>
                  <BarChartOutlined />
                </StyledIcon>
                <TitleH3> Sales & Marketing </TitleH3>
                <StyledParagraph>
                  <Anchor
                    affix={false}
                    direction="horizontal"
                    items={[
                      {
                        key: "phone",
                        href: "tel:+91-8950022329",
                        title: "+91-8950022329",
                        target: "__blank",
                      },
                    ]}
                  />
                </StyledParagraph>
              </StyledCard>
            </StyledCol>
            <StyledCol lg={6} xs={24}>
              <StyledCard bordered={false}>
                <StyledIcon>
                  <EnvironmentOutlined />
                </StyledIcon>
                <TitleH3> Address </TitleH3>
                <StyledParagraph>
                  Uttam Foundries & Ispat Pvt Ltd, Naraingarh Road, Village
                  Janetpur, Ambala City – 134 007,
                  Haryana (India)
                </StyledParagraph>
                <Anchor
                  affix={false}
                  direction="horizontal"
                  items={[
                    {
                      key: "location",
                      href: "https://goo.gl/maps/VwCmg8YkaDGDKPPCA",
                      title: "Location",
                      target: "__blank",
                    },
                  ]}
                />
              </StyledCard>
            </StyledCol>
            <StyledCol lg={6} xs={24}>
              <StyledCard bordered={false}>
                <StyledIcon>
                  <MailOutlined />
                </StyledIcon>
                <TitleH3> E-mail </TitleH3>
                <Anchor
                  affix={false}
                  direction="horizontal"
                  items={[
                    {
                      key: "mail",
                      href: "mailto:uttambharat64@gmail.com",
                      title: "uttambharat64@gmail.com",
                      target: "__blank",
                    },
                  ]}
                />
              </StyledCard>
            </StyledCol>
          </Row>
        </StyledDiv>
      </StyledContactDiv>
      <StyledDiv>
        <Row gutter={[24, 24]} align="middle" justify={"space-between"}>
          {/* <StyledMapCol xs={24} lg={14}>
            <iframe
              width="100%"
              title="Uttam Map"
              height="450"
              src="https://maps.google.com/maps?q=Uttam%20Foundries%20%26%20Ispat%20Pvt.%20Ltd.%20Ambala-Dehradun-Haridwar%20Road%2C%20Haryana&t=m&z=11&output=embed&iwloc=near"
            ></iframe>
          </StyledMapCol> */}
          <StyledCol lg={6} />
          <StyledCol xs={24} lg={12}>
            <StyledLeftAlignDiv>
              <StyledH1> Drop Us a Line </StyledH1>
              <ContactForm />
            </StyledLeftAlignDiv>
          </StyledCol>
          <StyledCol lg={6} />
        </Row>
      </StyledDiv>
    </AppLayout>
  );
};
