import React, { FC, useEffect } from "react";
import { Card, Image, List, Row } from "antd";
import { AppLayout } from "layouts";
import {
  StyledCol,
  StyledDiv,
  StyledImage,
  StyledImageCard,
  StyledBgWrapper,
  StyledList,
  StyledFoundryBgWrapper,
  StyledFoundryList,
} from "./Infrastructure.styled";
import { StyledH1 } from "routes/About/About.styled";
import { foundryList, machineShopData, testingImages } from "./constant";
import {
  StyledCard,
  StyledDot,
  StyledSmartDiv,
} from "routes/Dashboard/Dashboard.styled";

import {
  DIMENSIONAL_TESTING_EQUIPMENT,
  METALLOGRAPHIC_TESTING_EQUIPMENT,
  PHYSICAL_TESTING_EQUIPMENT,
  SAND_TESTING_EQUIPMENT,
} from "./constant";
import { StyledCarousel } from "ui/components/Carousel/Carousel.styled";
import { TitleH3 } from "ui";

const foundryImages = ["shell_core_shooter.png", "shell_core_shooter.png"];

const machineShopImages = ["factory3.jpeg", "factory3.jpeg"];

export const Infrastructure: FC = () => {
  useEffect(() => {
    document.title = `Uttam | Infrastructure`;
  }, []);

  return (
    <AppLayout>
      <StyledDiv>
        <StyledH1> Foundry </StyledH1>
        <Row gutter={[40, 40]}>
          <StyledCol lg={12} xs={24}>
            <StyledFoundryBgWrapper>
              <StyledFoundryList
                grid={{ gutter: 16, column: 2 }}
                itemLayout="horizontal"
                dataSource={foundryList}
                renderItem={(item: any) => (
                  <List.Item>
                    <TitleH3>
                      <StyledDot />
                      {item}
                    </TitleH3>
                  </List.Item>
                )}
              />
            </StyledFoundryBgWrapper>
          </StyledCol>
          <StyledCol lg={12} xs={24}>
            <StyledCarousel autoplay>
              {foundryImages.map((item, index) => (
                <React.Fragment key={index}>
                  <Card>
                    <Image
                      width="100%"
                      preview={false}
                      src={require(`../../assets/images/infra/${item}`)}
                    />
                  </Card>
                </React.Fragment>
              ))}
            </StyledCarousel>
          </StyledCol>
        </Row>
      </StyledDiv>
      <StyledSmartDiv>
        <StyledH1> Machine Shop </StyledH1>
        <Row gutter={[40, 40]}>
          <StyledCol lg={12} xs={24}>
            <StyledBgWrapper>
              <StyledList
                itemLayout="horizontal"
                dataSource={machineShopData}
                renderItem={(item: any) => (
                  <List.Item>
                    <TitleH3>
                      <StyledDot />
                      {item.title}
                    </TitleH3>
                  </List.Item>
                )}
              />
            </StyledBgWrapper>
          </StyledCol>
          <StyledCol lg={12} xs={24}>
            <StyledCarousel autoplay>
              {machineShopImages.map((item, index) => (
                <React.Fragment key={index}>
                  <StyledImageCard>
                    <Image
                      width="100%"
                      preview={false}
                      src={require(`../../assets/images/infra/${item}`)}
                    />
                  </StyledImageCard>
                </React.Fragment>
              ))}
            </StyledCarousel>
          </StyledCol>
        </Row>
      </StyledSmartDiv>
      <StyledDiv>
        <StyledH1> Testing Facilities </StyledH1>
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          <Row gutter={[40, 40]}>
            {testingImages.map((url, index) => (
              <StyledCol lg={8} md={12} xs={24} key={index}>
                <Card>
                  <StyledImage src={require(`../../assets/images/infra/${url}`)} />
                </Card>
              </StyledCol>
            ))}
          </Row>
        </Image.PreviewGroup>
      </StyledDiv>
      <StyledSmartDiv>
        <StyledH1> List of Testing Equipments </StyledH1>
        <Row gutter={[24, 24]}>
          <StyledCol lg={6} xs={24} sm={12}>
            <StyledCard title="SAND TESTING EQUIPMENT" bordered={false}>
              <List
                itemLayout="horizontal"
                dataSource={SAND_TESTING_EQUIPMENT}
                renderItem={(item) => (
                  <List.Item>
                    <StyledDot />
                    {item}
                  </List.Item>
                )}
              />
            </StyledCard>
          </StyledCol>
          <StyledCol lg={6} xs={24} sm={12}>
            <StyledCard
              title="METALLOGRAPHIC TESTING EQUIPMENT"
              bordered={false}
            >
              <List
                itemLayout="horizontal"
                dataSource={METALLOGRAPHIC_TESTING_EQUIPMENT}
                renderItem={(item) => (
                  <List.Item>
                    <StyledDot />
                    {item}
                  </List.Item>
                )}
              />
            </StyledCard>
          </StyledCol>
          <StyledCol lg={6} xs={24} sm={12}>
            <StyledCard title="PHYSICAL TESTING EQUIPMENT" bordered={false}>
              <List
                itemLayout="horizontal"
                dataSource={PHYSICAL_TESTING_EQUIPMENT}
                renderItem={(item) => (
                  <List.Item>
                    <StyledDot />
                    {item}
                  </List.Item>
                )}
              />
            </StyledCard>
          </StyledCol>
          <StyledCol lg={6} xs={24} sm={12}>
            <StyledCard title="DIMENSIONAL TESTING EQUIPMENT" bordered={false}>
              <List
                itemLayout="horizontal"
                dataSource={DIMENSIONAL_TESTING_EQUIPMENT}
                renderItem={(item) => (
                  <List.Item>
                    <StyledDot />
                    {item}
                  </List.Item>
                )}
              />
            </StyledCard>
          </StyledCol>
        </Row>
        <br />
      </StyledSmartDiv>
    </AppLayout>
  );
};
