import React, { useEffect, useState } from "react";
import { AutoComplete, Form, Input, InputNumber, Spin } from "antd";
import { StyledRoundButton } from "routes/Dashboard/Dashboard.styled";
import emailjs from "emailjs-com";
import { StyledAlert } from "../Contact.styled";
import { useNavigate } from "react-router-dom";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ContactForm: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const SERVICE_ID = "service_j0eyako";
  const TEMPLATE_ID = "template_prep67u";
  const USER_ID = "J_vihSm48X2tLQEuK";

  const onFinish = (e: any) => {
    setIsLoading(true);
    emailjs
      .sendForm(
        SERVICE_ID,
        TEMPLATE_ID,
        document.getElementById("contact") as HTMLFormElement,
        USER_ID
      )
      .then(
        (result) => {
          setFormSuccess(true);
          setIsLoading(false);
        },
        (error) => {
          setFormError(true);
          setIsLoading(false);
        }
      );
      form.resetFields();
  };

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  useEffect(() => {
    if (formSuccess) {
      navigate("/thankyou");
    }
  }, [formSuccess, navigate]);

  useEffect(() => {
    if (formError) {
      setTimeout(() => {
        setFormError(false);
        navigate("/");
      }, 3000);
    }
  }, [formError, navigate]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="contact"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item
        label="Your Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your name!",
          },
        ]}
      >
        <Input name="name" />
      </Form.Item>
      <Form.Item
        label="Your E-mail"
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input name="email" />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phone"
        rules={[{ required: true, message: "Please input your phone number!" }]}
      >
        <InputNumber name="phone" />
      </Form.Item>
      <Form.Item label="Your Website" name="website">
        <AutoComplete
          options={websiteOptions}
          onChange={onWebsiteChange}
        >
          <Input style={{ width: "100%" }} name="website" />
        </AutoComplete>
      </Form.Item>
      <Form.Item
        name="message"
        label="Your Comment"
        rules={[{ required: true, message: "Please input your comment" }]}
      >
        <Input.TextArea showCount maxLength={500} name="message" />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        {isLoading ? (
          <Spin />
        ) : (
          <StyledRoundButton
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
          >
            Submit
          </StyledRoundButton>
        )}
      </Form.Item>
      {formError && (
        <StyledAlert
          message="Something went wrong !"
          description="Try again later!"
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default ContactForm;
