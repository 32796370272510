import React, { FC, useEffect } from "react";
import { AppLayout } from "layouts";
import { StyledH1 } from "../About/About.styled";
import {
  StyledBgDiv,
  StyledDiv,
  StyledDownloadIcon,
  StyledImage,
} from "./OrganizationStructure.styled";
import { StyledCard } from "routes/Dashboard/Dashboard.styled";
import { Col, Row, Space } from "antd";
import FooterISO from "../../assets/images/org/iso-2.jpg";

export const OrganizationStructure: FC = () => {
  useEffect(() => {
    document.title = `Uttam | Organization Structure`;
  }, []);

  const onDownload = () => {
    const imageUrl = require("../../assets/images/org/iso.png");

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "uttam-certificate.png";

        link.click();

        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  return (
    <AppLayout>
      <StyledDiv>
        <StyledH1> Organization Structure </StyledH1>
        <StyledImage
          preview={false}
          src={require("../../assets/images/org/org-structure.png")}
        />
      </StyledDiv>
      <StyledBgDiv>
        <StyledH1> ISO Certification </StyledH1>
        <Row>
          <Col lg={{ span: 12, offset: 6 }} xs={{ span: 24, offset: 0 }}>
            <StyledCard bordered={false}>
              <StyledImage
                src={require("../../assets/images/org/iso.png")}
                preview={{
                  toolbarRender: (_) => (
                    <Space size={12} className="toolbar-wrapper">
                      <StyledDownloadIcon onClick={onDownload} />
                    </Space>
                  ),
                }}
              />
            </StyledCard>
          </Col>
        </Row>
      </StyledBgDiv>
      <StyledBgDiv>
        <StyledH1> QR Code of ISO Certification </StyledH1>
        <Row>
          <Col lg={{ span: 12, offset: 6 }} xs={{ span: 24, offset: 0 }}>
            <StyledCard bordered={false}>
              <StyledImage src={FooterISO} />
            </StyledCard>
          </Col>
        </Row>
      </StyledBgDiv>
    </AppLayout>
  );
};
