import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "styles";
import { AppRoutes } from "./AppRoutes";
import "./App.css";
import { ConfigProvider } from "antd";

export const App = () => (
  <ConfigProvider
    theme={{
      token: {
        fontFamily: "RubikRegular",
      },
    }}
  >
    <GlobalStyles />
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </ConfigProvider>
);
