import { HomeOutlined } from "@ant-design/icons";
import { Button, Drawer, Col, Typography, Divider, Image, Menu } from "antd";
import styled from "styled-components";
import {
  additionalSpace,
  colors,
  mediumLargeAndUp,
  mediumLargeAndDown,
  small,
  space,
  typography,
} from "ui/elements";

const { Title } = Typography;

export const StyledNav = styled.nav`
  [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none;
    -moz-animation: none;
    -o-animation: none;
    -ms-animation: none;
    animation: none;
  }
`;

export const StyledLogoCol = styled(Col)`
  a img {
    max-height: 100px;
    vertical-align: middle;
  }
`;

export const StyledButton = styled(Button)`
  box-shadow: none;
  border-color: ${colors.white};
  outline: none;
  font-size: ${space.moderate};
  margin-top: ${space.normal};
  z-index: 10000;
  :hover,
  :focus,
  :active {
    color: ${colors.hoverBlue};
    border-color: transparent;
  }
`;

export const StyledDrawer = styled(Drawer)`
  padding-top: 100px;
  .ant-menu-item {
    font-size: ${typography.size.moderate};
  }
  .ant-drawer-mask {
    background: #fff !important;
  }
  ${small`
    padding-top: 85px;
  `}
`;

export const StyledTitle = styled(Title)`
  &.ant-typography {
    margin-top: ${typography.size.moderate};
    margin-left: ${typography.size.moderate};
    font-size: ${typography.size.zetta};
    ${small`
    font-size: 12px;
  `}
  }
`;

export const StyledHome = styled(HomeOutlined)`
  font-size: ${typography.size.giga};
`;

export const StyledSpan = styled.span`
  font-size: ${typography.size.giga};
  ${small`
  display: none !important;
  `}
`;

export const StyledDivider = styled(Divider)`
  border-left: ${space.micro} solid ${colors.lightGrey};
  height: ${additionalSpace.seventyFive};
  ${small`
  margin-top: 8px;
  `}
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledNavCol = styled(Col)`
  display: flex;
  justify-content: "flex-end";
  ${mediumLargeAndDown`
  display: none !important;
  `}
  ${mediumLargeAndUp`
  display: block !important;
  `}
`;

export const StyledSmallNavCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  ${mediumLargeAndUp`
    display: none !important;
  `}
  ${mediumLargeAndDown`
    display: block !important;
  `}
`;

export const StyledMenu = styled(Menu)`
  justify-content: flex-end;
  .ant-menu-item-active {
    color: ${colors.themeOrangeSecondary} !important;
  }
  .ant-menu-item-active::after {
    border-bottom-color: ${colors.themeOrangePrimary} !important;
  }
  .ant-menu-submenu-active {
    color: ${colors.themeOrangeSecondary};
  }
  .ant-menu-item-selected {
    color: ${colors.themeOrangeSecondary} !important;
  }
  .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: ${colors.themeOrangeSecondary} !important;
  }
  .ant-menu-item-selected::after {
    border-bottom-color: ${colors.themeOrangePrimary} !important;
  }
  .ant-menu-submenu-active::after {
    border-bottom-color: ${colors.themeOrangePrimary} !important;
  }
  .ant-menu-submenu-selected::after {
    border-bottom-color: ${colors.themeOrangePrimary} !important;
  }
  .ant-menu-submenu-inline .ant-menu-title-content{
    font-size: 20px
  }
  .ant-menu-item-selected .ant-menu-title-content{
    ${mediumLargeAndUp`
      color: #FF4D00 !important
    }`
  }
  .ant-menu-sub .ant-menu-item .ant-menu-title-content{
    color: ${colors.blackRgba} !important;
   }
`;

export const StyledImage = styled(Image)`
  padding-top: 10px;
  padding-right: 50px;
  width: 450px !important;
  ${small`
    width: 300px !important;
  `}
`;
