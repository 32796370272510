import { Image } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import { colors, small, typography } from "ui";

export const StyledDiv = styled.div`
  margin: 50px;
  ${small`
    margin: 25px 0px;
  }`}
  .ant-image {
    display: flex;
  }
`;

export const StyledImage = styled(Image)`
  padding: 20px 10px;
`;

export const StyledBgDiv = styled.div`
  background-color: ${colors.white};
  padding: 5px 50px 30px 50px;
  ${small`
    padding: 10px;
  `}
  .ant-card {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    height: 100%;
  }
  .ant-card-body {
    ${small`
      padding: 0px;
  `}
  }
`;

export const StyledDownloadIcon = styled(DownloadOutlined)`
  background-color: ${colors.white};
  padding: ${typography.size.mini};
  color: ${colors.black};
  font-size: ${typography.size.zetta};
`