import { Col, Image } from "antd";
import { styled } from "styled-components";
import { small, TitleH3 } from "ui";

export const StyledDiv = styled.div`
  margin: 50px;
  ${small`
    margin: 25px 0px;
  }`}
`;

export const StyledImage = styled(Image)`
  padding: 20px 10px;
`;

export const StyledCol = styled(Col)`
  .ant-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    height: 100%;
  }
  .ant-image img{
    padding: 0;
    border-radius: 20px;
  }
`;

export const StyledH3 = styled(TitleH3)`
  padding-bottom: 20px;
  text-align: center;
`
