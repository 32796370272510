export const constants = {
  breakpoints: {
    smallAndUp: "(min-width: 480px)",
    xSmallAndDown: "(max-width: 479px)",
    small: "(max-width: 767px)",
    medium: "(min-width: 768px)",
    large: "(min-width: 1024px)",
    mediumAndUp: "(min-width: 768px)",
    mediumAndDown: "(max-width: 1024px)",
    largeAndUp: "(min-width: 1024px)",
    midLargeAndUp: "(min-width: 1280px)",
    midLargeAndDown: "(max-width: 1280px)",
    mediumLargeAndDown: "(max-width: 1350px)",
    mediumLargeAndUp: "(min-width: 1350px)",
    xLarge: "(min-width: 1440px)",
    xLargeAndUp: "(min-width: 1440px)",
  },
  drawer: {
    width: 460,
  },
};

export const typography = {
  weight: {
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
  size: {
    mini: "10px",
    uno: "12px",
    hecto: "14px",
    kilo: "16px",
    giga: "18px",
    moderate: "20px",
    tera: "24px",
    zetta: "32px",
    bronto: "40px",
  },
};
