import { Row, Col, Anchor } from "antd";
import React, { FC } from "react";
import {
  StyledDiv,
  StyledContactDiv,
  StyledRow,
  StyledCopyRight,
} from "./footer.styled";
import {
  PhoneOutlined,
  EnvironmentOutlined,
  MailOutlined,
} from "@ant-design/icons";
export const FooterComponent: FC = () => {
  return (
    <>
      <StyledDiv>
        <StyledRow gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={6}>
                <PhoneOutlined />
              </Col>
              <Col xs={24} md={18}>
                <StyledContactDiv>
                  <b>Sales Number:</b>
                </StyledContactDiv>
                <StyledContactDiv>
                  <Anchor
                    affix={false}
                    direction="horizontal"
                    items={[
                      {
                        key: "phone",
                        href: "tel:+91-8950022329",
                        title: "+91-8950022329",
                      },
                    ]}
                  />
                </StyledContactDiv>
                <StyledContactDiv>
                  <Anchor
                    affix={false}
                    direction="horizontal"
                    items={[
                      {
                        key: "phone",
                        href: "tel:+91-9416499415",
                        title: "+91-9416499415",
                      },
                    ]}
                  />
                </StyledContactDiv>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={8}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={6}>
                <EnvironmentOutlined />
              </Col>
              <Col xs={24} md={18}>
                <StyledContactDiv>
                  <b>Address:</b>
                </StyledContactDiv>
                <StyledContactDiv>
                  Uttam Foundries & Ispat Pvt Ltd, Naraingarh Road, Village
                  Janetpur, Ambala City – 134 007, Haryana (India)
                </StyledContactDiv>
                <StyledContactDiv>
                  <Anchor
                    direction="horizontal"
                    affix={false}
                    items={[
                      {
                        key: "location",
                        href: "https://goo.gl/maps/VwCmg8YkaDGDKPPCA",
                        title: "Location",
                        target: "__blank",
                      },
                    ]}
                  />
                </StyledContactDiv>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={8}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={6}>
                <MailOutlined />
              </Col>
              <Col xs={24} md={18}>
                <StyledContactDiv>
                  <b>E-mail:</b>
                </StyledContactDiv>
                <StyledContactDiv>
                  <Anchor
                    direction="horizontal"
                    affix={false}
                    items={[
                      {
                        key: "mail",
                        href: "mailto:uttambharat64@gmail.com",
                        title: "uttambharat64@gmail.com",
                        target: "__blank",
                      },
                    ]}
                  />
                </StyledContactDiv>
              </Col>
            </Row>
          </Col>
        </StyledRow>
      </StyledDiv>
      <StyledCopyRight>
        <StyledContactDiv>
          {`© ${new Date().getFullYear()} Uttam. All Rights Reserved`}{" "}
        </StyledContactDiv>
      </StyledCopyRight>
    </>
  );
};

export const Footer = React.memo(FooterComponent);
